import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
// import Certificate from "../../../Pages/Certificate/Certificate";

function NavScrollExample({ About, Yourproperty, Plotdetail, Certificate }) {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  return (
    <>
      <Navbar expand="xl " className="navbar-custom navbar-desktop">
        <Container fluid className="Container-whole">
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto ">
              <Nav.Link className="me-2 d-flex align-items-center justify-content-center">
                100% Claimed
                <img
                  src="/Images/Navbar/line.svg"
                  alt="line"
                  className="line-img"
                />
              </Nav.Link>
              <Nav.Link
                className="me-2"
                // style={{ width: "100%" }}
                onClick={() => navigate("/PropertiesFAQ")}
              >
                FAQ <br />
                Properties
              </Nav.Link>
              <Nav.Link
                onClick={() => navigate("/yourproperty")}
                className="me-2"
                // style={{ width: "127.29px" }}
              >
                Buy <br />
                Properties
              </Nav.Link>
            </Nav>

            <Nav
              className={About ? "interstellar-nav ms-auto me-auto" : "d-none"}
            >
              <img
                src="/Images/Navbar/nav-interstellar.svg"
                alt="interstellar"
                className="interstellar-img"
              />
            </Nav>

              <Nav
                className={
                  Yourproperty ? "interstellar-nav ms-auto me-auto" : "d-none"
                }
              >
                <img
                  src="/Images/Navbar/nav-interstellar.svg"
                  alt="interstellar"
                  className="interstellar-img"
                />
              </Nav>

              <Nav
                className={
                  Plotdetail ? "interstellar-nav ms-auto me-auto" : "d-none"
                }
              >
                <img
                  src="/Images/Navbar/nav-interstellar.svg"
                  alt="interstellar"
                  className="interstellar-img"
                />
              </Nav>
              <Nav
                className={
                  Certificate ? "interstellar-nav ms-auto me-auto" : "d-none"
                }
              >
                <img
                  src="/Images/Navbar/nav-interstellar.svg"
                  alt="interstellar"
                  className="interstellar-img"
                />
              </Nav>

            <Nav className="ms-auto">
              <Nav.Link
                onClick={() => navigate("/Certificate")}
                className="me-2"
                // style={{ width: "132.29px" }}
              >
                Buy <br />
                CITIIZENSHIP
              </Nav.Link>
              <Nav.Link
                onClick={() => navigate("/CitizenshipFAQ")}
                className="me-2"
                // style={{ width: "132.29px" }}
              >
                FAQ <br /> Citiizenship
              </Nav.Link>
              {/* <Nav.Link className="me-2" onClick={() => navigate("/About")}>
                <div className="btn">
                  <button onClick={toggleDropdown} className="drop-btn">
                    <span>About</span>
                    <img
                      src="/Images/Navbar/dropdownIcon.svg"
                      alt="icon"
                      className="dropdown-img"
                    />
                  </button>
                </div>
                <div className="showBtn">
                  {showDropdown && (
                    <div className="dropdown-content">
                      <div>
                        <a href="/">BIIO Metaverse</a>
                      </div>
                      <div className="option">
                        <a href="/">Properties & Citiizenships</a>
                      </div>
                      <div className="option">
                        <a href="/">Titles & Procedures</a>
                      </div>
                    </div>
                  )}
                </div>
              </Nav.Link> */}
              <NavDropdown
                title={<span >About</span>}
                id="collapsible-nav-dropdown "
                className="me-2 About-dropdown"
              >
                 {/* <NavDropdown.Item href="/About" >About</NavDropdown.Item> */}

                <NavDropdown.Item onClick={() => navigate("/About")}>BIIO Metaverse</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/home")}>
                  Properties & Citiizenships
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/TilesAndProcedures")}>Titles & Procedures</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                className="me-2 d-flex align-items-center justify-content-center"
                onClick={() => navigate("/Home")}
                // style={{ width: "100%" }}
              >
                Home
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Navbar expand="xl " className="navbar-custom navbar-mobile">
        <Container fluid>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="ms-auto">
              <Nav.Link className="me-2" onClick={() => navigate("/Home")}>
                Home
              </Nav.Link>

              {/* <Nav.Link className="me-2" onClick={() => navigate("/About")}>
                <div className="btn">
                  <button onClick={toggleDropdown} className="drop-btn">
                    <span>About</span>
                    <img
                      src="/Images/Navbar/dropdownIcon.svg"
                      alt="icon"
                      className="dropdown-img"
                    />
                  </button>
                </div>
                <div className="showBtn">
                  {showDropdown && (
                    <div className="dropdown-content">
                      <div>
                        <a href="/">BIIO Metaverse</a>
                      </div>
                      <div className="option">
                        <a href="/">Properties & Citiizenships</a>
                      </div>
                      <div className="option">
                        <a href="/">Titles & Procedures</a>
                      </div>
                    </div>
                  )}
                </div>
              </Nav.Link> */}

              <NavDropdown
               title={<span  onClick={() => navigate("/About")}>About</span>}
                id="collapsible-nav-dropdown "
                className="me-2 About-dropdown d-flex flex-column "
              >
                 {/* <NavDropdown.Item href="/About" onClick={() => navigate("/About")}>About</NavDropdown.Item> */}
                <NavDropdown.Item onClick={() => navigate("/About")}>BIIO Metaverse</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/home")}>
                  Properties & Citiizenships
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/TilesAndProcedures")}>Titles & Procedures</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                onClick={() => navigate("/CitizenshipFAQ")}
                className="me-2"
              >
                FAQ Citizenship
              </Nav.Link>
              <Nav.Link
                onClick={() => navigate("/Certificate")}
                className="me-2"
              >
                BUY CITIIZENSHIPS
              </Nav.Link>
            </Nav>
            <Nav className="me-auto ">
              <Nav.Link
                onClick={() => navigate("/yourproperty")}
                className="me-2"
              >
                Buy Properties
              </Nav.Link>
              <Nav.Link
                className="me-2"
                onClick={() => navigate("/PropertiesFAQ")}
              >
                FAQ Properties
              </Nav.Link>
              <Nav.Link className="navlink-custom me-2" >
                100% Claimed
                <img
                  src="/Images/Navbar/line.svg"
                  alt="line"
                  className="line-img"
                />
              </Nav.Link>
            </Nav>

            <Nav
              className={About ? "interstellar-nav ms-auto me-auto" : "d-none"}
            >
              <img
                src="/Images/Navbar/nav-interstellar.svg"
                alt="interstellar"
                className="interstellar-img"
              />
            </Nav>

            {/* <Nav
              className={
                Yourproperty ? "interstellar-nav ms-auto me-auto" : "d-none"
              }
            >
              <img
                src="/Images/Navbar/nav-interstellar.svg"
                alt="interstellar"
                className="interstellar-img"
              />
            </Nav>

            <Nav
              className={
                Plotdetail ? "interstellar-nav ms-auto me-auto" : "d-none"
              }
            >
              <img
                src="/Images/Navbar/nav-interstellar.svg"
                alt="interstellar"
                className="interstellar-img"
              />
            </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavScrollExample;
