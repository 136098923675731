import contractAbi from "../../utils/Contracts/contractAbi.json";
import approveAbi from "../../../src/utils/Contracts/approve.json";
import citizennShip from "../../utils/Contracts/ctizenship.json";



import {
  MintContract,
  ApprovedToken,
  CitizenShip
} from "../../utils/Contracts/ContractAddress";
import { useCallback } from "react";


export const useGetPlot = ({ account }) => {
  const plotRead = useCallback(
    async (account, customweb3) => {
      const contract = new customweb3.eth.Contract(citizennShip, CitizenShip);
      try {
        const estimatedGas = await contract.methods
          .getCitizenshipInfoByAddress(account)
          .estimateGas();

          const details = await contract.methods.getCitizenshipInfoByAddress(account).call({
            gas: estimatedGas,
          });

        return details;
      } catch (error) {
        console.log(error, "error");
        throw error;
      }
    },
    [account]
  );
  return { plotRead: plotRead };
};
export default useGetPlot;
