import React from "react";
import "./NewNewLandingPage.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import HomeScreenLoader from "../../Components/loader/HomeScreenLoader";
import MarsTexture from "../../Components/Mars/MarsTextures";

const NewNewLandingPage = () => {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 5000); // Hide loader after 30 seconds
  }, []);

  return (
    <>
      {showLoader && <HomeScreenLoader />}
      <div className="NewNewLandingPage__main">
        <div className="NewNewLandingPage__main-body">
          <img
            src="/Images/Landingpg/Mars.svg"
            alt="/"
            className="NewNewLandingPage__main-body-mars"
          />
          <div className="NewNewLandingPage__main-body-model">
            <MarsTexture />
            
          </div>
          <button
            className="NewNewLandingPage__main-body-btn"
            onClick={() => navigate("/Home")}
          >
           Be MARTIIAN
          </button>
        </div>
        <div className="Mars_HerO_Down_Container">
        <div className="NewNewLandingPage__main-body-txt">
          <p>This website requires web3.js</p>
          <div className="NewNewLandingPage__main-body-txt-btn">
            <p>Built with Ethereum</p>
            <img src="Images/About/etherum.svg" alt="/" />
          </div>
        </div>
        <div className="footer-copyright" style={{ paddingBottom: "100px" }}>
          <img
            src="/Images/Navbar/Footer-copyright.svg"
            alt="Copyright"
            className="copyr-img"
          />
        </div>
        </div>
      </div>
    </>
  );
};

export default NewNewLandingPage;
