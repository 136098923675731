import React, { useEffect, useState, useRef } from "react";
import Navbar from "../../Components/Custom/Navbar/Navbar";
import Footer from "../../Components/Custom/Footer/Footer";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../../hooks/Web3Connection/useWeb3";
import { ErrorToast, SuccessToast } from "../../Components/toast/Toast";
import Web3Modal from "../../Components/modal/Web3Modal";
import ScreenLoader from "../../Components/loader/ScreenLoader";
import Spinner from "react-bootstrap/Spinner";
import "./certificate.css";
import useCitizenship from "../../hooks/Contract/useCitizenship";
import useMintRead from "../../hooks/Contract/useMintRead";
import useGetPlot from "../../hooks/Contract/useGetPlot";
import html2canvas from "html2canvas";
import axios from "axios";
import useSetBaseUri from "../../hooks/Contract/useSetBaseUri";

const pinataApiKey = "c0ddc01490bd65cff005";
const pinataSecretApiKey =
  "295dc623ca3bbe61da51f738c78530492c6e00dd7a8e13e2d2cd250073ea8c2c";
const pinataBaseUrl = "https://api.pinata.cloud/pinning/pinFileToIPFS";
const pinataBaseUrl2 = "https://api.pinata.cloud";

const Certificate = () => {
  const [open, setOpen] = useState(false);
  const { account, chainId } = useWeb3React();
  const [userid, setUserId] = useState();
  const customweb3 = useWeb3();
  const { citizenHook } = useCitizenship({ account });
  const { setBaseUri } = useSetBaseUri({ account });
  const { mintRead } = useMintRead({ account });
  const { plotRead } = useGetPlot({ account });

  const [loader, setLoader] = useState(false);
  const [userData, setUserData] = useState();
  const [dataLoader, setDataLoader] = useState(false);
  const [certificatioNo, setCertificateNo] = useState();
  const [certificateUpdate, setCertificateUpdate] = useState(false);
  const divRef = useRef(null);
  const [selectedFraction, setSelectedFraction] = useState(null);
  const [selectesFractionNumber, setSelectedFractionNumber] = useState(null);
  const [plotNumbers, setPlotNumbers] = useState();
  const [loaderMint, setLoaderMInt] = useState();

  const handleRadioChange = (item) => {
    // Update the selectedFraction state with the index of the radio button clicked
    // setSelectedFraction(item);
    setSelectedFractionNumber(item);
  };

  const uploadToPinata = async (file, existingFolderCID) => {
    const formData = new FormData();
    formData.append("file", file);

    const metadata = JSON.stringify({
      name: file.name,
      keyvalues: {
        folder: existingFolderCID,
      },
    });

    formData.append("pinataMetadata", metadata);

    const options = JSON.stringify({
      cidVersion: 0,
    });

    formData.append("pinataOptions", options);

    try {
      const response = await axios.post(pinataBaseUrl, formData, {
        maxBodyLength: "Infinity", // this is needed to prevent axios from erroring out with large files
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          pinata_api_key: pinataApiKey,
          pinata_secret_api_key: pinataSecretApiKey,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error uploading file to Pinata", error);
      throw error;
    }
  };

  const uploadJSONToPinata = async (json, fileName) => {
    const data = {
      pinataMetadata: {
        name: fileName,
      },
      pinataContent: json,
    };

    try {
      const response = await axios.post(
        `${pinataBaseUrl2}/pinning/pinJSONToIPFS`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            pinata_api_key: pinataApiKey,
            pinata_secret_api_key: pinataSecretApiKey,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error uploading JSON to Pinata", error);
      throw error;
    }
  };

  const handleContract = async () => {
    if (!account) {
      setOpen(true);
      return;
    }
    if (!selectesFractionNumber) {
      ErrorToast("Please Select a plot");
      return;
    }
    setLoaderMInt(true);

    try {
      console.log(selectesFractionNumber, "this is fraction");
      const hash = await citizenHook(
        account,
        customweb3,
        parseInt(selectesFractionNumber)
      );
      if (hash) {
        setCertificateUpdate(true);
        // setSelectedFraction(hash?.events?.Transfer?.returnValues?.tokenId)
        setSelectedFraction(hash?.events?.Transfer?.returnValues?.tokenId)
        setPlotNumbers(selectesFractionNumber)

        console.log(
          "this is certificate hash",
          hash?.events?.Transfer?.returnValues?.tokenId
        );
        await handleGetPlot();

        try {
          const div = divRef.current;
          const canvas = await html2canvas(div);
          const dataURL = canvas.toDataURL("image/png");

          const byteString = atob(dataURL.split(",")[1]);
          const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([ab], { type: mimeString });

          // Create a File object
          const file = new File([blob], `Certificate ${`${hash?.events?.Transfer?.returnValues?.tokenId}`}.png`, {
            type: "image/png",
          });

          const result = await uploadToPinata(file);
          console.log(result.IpfsHash);

          const metadata = {
            description: "An image generated from a div",
            image: `ipfs://${result.IpfsHash}`,
            attributes: [
              {
                trait_type: "CitiizenShip Certificate",
                value: `${hash?.events?.Transfer?.returnValues?.tokenId}`,
              },
            ],
          };
          console.log("Uploaded to IPFS:", result.IpfsHash);
          const metadataResult = await uploadJSONToPinata(metadata,`${hash?.events?.Transfer?.returnValues?.tokenId}`);
          console.log("Metadata uploaded to IPFS:", metadataResult.IpfsHash);

          const hashss = await setBaseUri(
            account,
            customweb3,
            parseInt(hash?.events?.Transfer?.returnValues?.tokenId),
            `ipfs://${metadataResult.IpfsHash}`
          );
          if (hashss) {
        SuccessToast("Hurra! You Successfully Buy a CitizenShip on mars");

            setLoaderMInt(false);
          }
        } catch (error) {
          console.error("Error uploading file to Pinata", error);
        }
      }
    } catch (error) {
      ErrorToast(error.message);
      setLoaderMInt(false);
    }
  };

  const hadleGetValue = async () => {
    setLoader(true);
    try {
      const hash = await mintRead(account, customweb3);
      setLoader(false);
      setUserData(hash);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const truncatedAddress = `${account?.substring(
    0,
    6
  )}....................${account?.substring(account?.length - 6)}`;
  useEffect(() => {
    if (account) {
      hadleGetValue();
    }
  }, [account]);

  // useGetPlot
  const handleGetPlot = async () => {
    setLoader(true);
    try {
      const hash = await plotRead(account, customweb3);
      console.log("this is the hash", hash);
      setCertificateUpdate(true);
      setSelectedFraction(hash[0]);
      setPlotNumbers(hash[1]);

      // console.log("this is hash data,", hash[0]);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const truncatedPlot = `${account?.substring(
    0,
    6
  )}....................${account?.substring(account?.length - 6)}`;
  useEffect(() => {
    if (account) {
      handleGetPlot();
    }
  }, [account, certificateUpdate]);

  const handleCaptureClick = async () => {
    const div = divRef.current;
    if (div) {
      try {
        const canvas = await html2canvas(div);
        const dataURL = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = dataURL;
        link.download = "certificate.png";
        // setLoader(false)
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error generating image:", error);
        setLoader(false);
      }
    }
  };

  return (
    <>
      {(loader || loaderMint) && <ScreenLoader />}

      <div className="main__certificate">
        <Navbar Certificate={true} />
        <div className="main__certificate-hero">
          <div className="main__certificate-citezenship">
            <img src="/Images/Certificate/citizenship.svg" alt="" />
          </div>
          <div className="ethereum-contain-certificate">
            <img
              src="/Images/About/etherum.svg"
              alt="Ethereum"
              className="ethereum-img-certificate"
            />
            <p className="ethereum-txt-certificate">Built with Ethereum</p>
          </div>
        </div>
        <div className="main__certificate-body">
          <div className="main__certificate-left">
            <h3>M.A.R.S. Properties</h3>
            <p>
              Please select plot number along with fractions owned to become an
              IINTERSTELLAR Citiizen
            </p>
            <div className="main__certificate-left-bgcard">
              <div className="main__certificate-left-card1">
                <p>{account ? truncatedAddress : "no account"}</p>
              </div>
              {userData &&
                userData[0]?.map((items, index) => {
                  return (
                    <>
                      <div key={index} className="main__certificate-left-card2">
                        <div className="radio__buttons">
                          <input
                            type="radio"
                            id={"radio1"}
                            className="radio-group"
                            name="radio"
                            onChange={() => handleRadioChange(items)}
                            checked={selectesFractionNumber === items}
                          />
                          <label for="radio1">
                            Plot {items}{" "}
                            <span>
                              Fraction(s) owned:{" "}
                              {userData && userData[1][index]}
                            </span>
                          </label>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
            <div className="main__certificate-left-btn">
              <button
                onClick={() => {
                  handleContract();
                }}
              >
                Citiizenship
              </button>
            </div>
          </div>
          <div className="main__certificate-right">
            <div ref={divRef} style={{ position: "relative" }}>
              <img src="/Images/Certificate/certificate.svg" alt="/" />

              {selectedFraction && selectedFraction !== "0" && (
                <div className="plot-main-head">
                  <h3
                    className="certificatio-noValue"
                    style={{ color: "black", margin: "0px" }}
                  >
                    {selectedFraction}
                  </h3>
                </div>
              )}

              {plotNumbers && plotNumbers !== null && (
                <div className="plot-main">
                  <h3
                    className="certificatio-noValue"
                    style={{ color: "black", margin: "0px" }}
                  >
                    {plotNumbers}
                  </h3>
                </div>
              )}
            </div>

            {/* <button onClick={handleCaptureClick}>
                
              </button> */}

            {plotNumbers && plotNumbers !== null && (
              <div className="main__certificate-left-btn">
                <button onClick={handleCaptureClick}>Downnload</button>
              </div>
            )}
            <p
              onClick={() => {
                window.location.href = "https://guardiianwallet.io/";
              }}
            >
              Install GUARDIIAN Wallet Here
            </p>
          </div>
        </div>
        <div className="certificate__footer">
          <Footer />
        </div>
      </div>
      <Web3Modal show={open} setModalShows={setOpen} />
    </>
  );
};

export default Certificate;
