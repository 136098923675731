import Accordion from "react-bootstrap/Accordion";
import "./Accordion.css";
function FlushExample({ imglogo, headtxt, bodytxt }) {
  return (
    <Accordion defaultActiveKey="1" flush className="home-accordion">
      <Accordion.Item eventKey="0" >
        <Accordion.Header className="head-contain">
          <img src={imglogo} alt="whymars" className="whymars-img" />
          <p className="heading">{headtxt}</p>
        </Accordion.Header>
        <Accordion.Body>{bodytxt}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default FlushExample;
