import contractAbi from "../../utils/Contracts/ctizenship.json";
import { CitizenShip,ApprovedToken } from "../../utils/Contracts/ContractAddress";
import approveAbi from "../../utils/Contracts/approve.json";
import { useCallback } from "react";


export const useSetBaseUri = ({ account }) => {
  const setBaseUri = useCallback(
    async (account, customweb3, plotId,baseURI) => {
      console.log("inside the contract");
      const contract = new customweb3.eth.Contract(contractAbi, CitizenShip);
      const approvecontract = new customweb3.eth.Contract(
        approveAbi,
        ApprovedToken
      );
      try {
        const fee1=49 
        const feeinString=fee1.toString()
        const valuesend = customweb3.utils.toWei(feeinString, "ether");

        console.log(account,customweb3,plotId)
          const estimatedGas = await contract.methods
          ._setTokenURI(plotId,baseURI)
          .estimateGas({
            from: account,
          });
        const details = await contract.methods._setTokenURI(plotId,baseURI).send({
          from: account,
          gas: estimatedGas,
        });

        console.log("Transaction details:", details);
        return details;
      } catch (error) {
        throw error;
      }
    },
    [account]
  );
  return { setBaseUri: setBaseUri };
};
export default useSetBaseUri;
