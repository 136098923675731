import contractAbi from "../../utils/Contracts/ctizenship.json";
import { CitizenShip,ApprovedToken } from "../../utils/Contracts/ContractAddress";
import approveAbi from "../../utils/Contracts/approve.json";
import { useCallback } from "react";


export const useCitizenshipClaim = ({ account }) => {
  const citizenClaimHook = useCallback(
    async (account, customweb3, plotId) => {
      console.log("inside the contract");
      const contract = new customweb3.eth.Contract(contractAbi, CitizenShip);
      try {
       
        const estimatedGas = await contract.methods
          .claimTokens(plotId)
          .estimateGas({
            from: account,
          });
          
       

        const details = await contract.methods.claimTokens(plotId).send({
          from: account,
          gas: estimatedGas,
        });

        return details;
      } catch (error) {
        console.log(error, "errpr");
        throw error;
      }
    },
    [account]
  );
  return { citizenClaimHook: citizenClaimHook };
};
export default useCitizenshipClaim;
