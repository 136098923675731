import { Row, Col, Container } from "react-bootstrap";
import "./PrivacyPolicy.css";
import Button from "react-bootstrap/Button";
import Navbar from "../../Components/Custom/Navbar/Navbar";
import Footer from "../../Components/Custom/Footer/Footer";

export default function PrivacyPolicy() {
  return (
    <>
      <Container fluid className="privacy-contain">
      {/* <img src="\Images\Home\galaxy-bg-starts.svg" alt="img" className="img-fluid bg-home-starts" /> */}
        <Navbar />

        <Row>
          <Col className="sec-head">
            <img
              src="/Images/Properties-FAQ/Sec1-PrivacyPolicy.svg"
              alt="Privacy-Policy"
              width={"100%"}
            />
            <div>
              <Button type="button" className="btn-BeThere-PP">
                Be There Now
              </Button>
              <div className="polygon-contain-pp">
                {/* <img
                  src="/Images/Home/polygon.svg"
                  alt="Polygon"
                  className="poly-img"
                /> */}
                <p className="polygon-txt-pp" onClick={ () => {window.location.href="https://guardiianwallet.io/"} }>Install GUARDIIAN Wallet Here</p>
              </div>
            </div>
          </Col>
        </Row>
        {/* PrivacyPolicy content */}
        <Row>
          <Col className="content-whole">
            <div className="content-inside">
              <div>
                <p className="head-label">Last Updated: Jan 3, 2024</p>
                <p className="subhead">
                  This Privacy Notice describes the policies and procedures of
                  Corporation, (“we,” “our,” or “us”) pertaining to the
                  collection, use, and disclosure of your information
                  on www.IINTERSTELLAR.io and related mobile applications and
                  products we offer (the “Services” or “IINTERSTELLAR™”).
                </p>
                <p className="description-pp">
                  This Privacy Notice describes the policies and procedures of
                  of Corporation (“we,” “our,” or “us”) pertaining to the
                  collection, use, and disclosure of your information
                  on{" "}
                  <a
                    href="https://www.IINTERSTELLAR.io"
                    alt="IINTERSTELLAR.io"
                    className="a-style"
                  >
                    www.IINTERSTELLAR.io
                  </a>{" "}
                  and related mobile applications and products we offer (the
                  “Services” or “IINTERSTELLAR™”).
                </p>
              </div>
              <div>
                <p className="head-label">Overview</p>
                <p className="description-p2">
                  Your right to privacy and the protection of your personal data
                  is important to us. The following sections provide further
                  details as to how we process your personal
                  information through IINTERSTELLAR™. We don't share your
                  information with third parties except to deliver you our
                  IINTERSTELLAR™ better, protect our rights, or effectuate a
                  business transfer. We're not a huge, faceless
                  corporation. We're a platform “For the People, by the People.”
                </p>
              </div>
              <div>
                <p className="head-label">How do you accept this policy</p>
                <p className="description-p2">
                  By using IINTERSTELLAR™, including downloading one of our
                  mobile applications or visiting our website, you agree to the
                  use, disclosure, and procedures outlined in this
                  Privacy Policy.
                </p>
              </div>
              <div>
                <p className="head-label">
                  What personal information do we collect from our users
                </p>
                <p className="description-p2">
                  We do our best to minimize the amount of Personal Information
                  that we collect from IINTERSTELLAR™ users. Your contact
                  information, such as your phone number,, or email
                  address (depending on how you contact us), may be collected
                  when you communicate with us for support, or if you report a
                   bug or other error related to IINTERSTELLAR™ or when
                  you interact with us through social media. When you use our
                  Services, we process PUBLIC wallet addresses that you
                  may generate through IINTERSTELLAR™.
                </p>
              </div>

              <div>
                <p className="head-label">
                  Will we share your personal date with third parties
                </p>
                <p className="description-p2">
                  Information about our users is an important part of our
                  business and we are not in the business of selling our users'
                  personal information to others. We may transfer
                  personal data to our service providers or third parties in
                  connection with IINTERSTELLAR™'s operation of its business, as
                  certain features on IINTERSTELLAR™ rely on various
                  third-party products and services (collectively “Third Party
                  Services”). These third party services providers only{" "}
                  have access to certain Personal Information, such as your
                  public Wallet addresses, to perform their functions and may
                  not use it for other purposes. Furthermore, they must
                  process the personal information in accordance with our
                  contractual agreements and only as permitted by applicable
                  data protections laws. Business transfers: As we
                  continue to develop our business,  we might sell or buy other
                  businesses or services. In such transactions, user
                  information generally is one of the transferred business
                  assets but remains subject to the promises made in any
                  pre-existing Privacy notice (unless, of course, the
                  user consents otherwise). Also, in the unlikely event that
                  IINTERSTELLAR™ or substantially all of its assets are
                  acquired, users' information will be one of the
                  transferred assets. We may share non-personally identifiable
                  information publicly and with our partners- like publishers,
                  advertisers, developers, or right holders.
                </p>
              </div>

              <div>
                <p className="head-label">
                  Your rights with respect to the processing of personal data
                </p>
                <p className="description-p2">
                  You are entitled (under the conditions, and subject to the
                  exceptions, set out in applicable law) to: Request Access to
                  the personal information we process about you: You
                  can request access to the information we have collected from
                  you. You can do this by contacting us{" "}
                  at:Support@IINTERSTELLAR.io. We will provide you with a copy
                  of the data we process about you. To comply with your request,
                  we may ask you to verify your identity. We will
                  fulfill your request by sending your copy electronically. For
                  any subsequent access request, we may charge you an{" "}
                  administrative fee. Request a rectification of your personal
                  data: If you believe that the information we have collected is
                  incorrect or incomplete, you may contact us so we can
                  update it and keep your data accurate. Object to the
                  processing of your personal data: you may request that we no
                  longer process your personal data. Request to erase
                  your personal data: you may request the erasure of your
                  personal data, including where such personal data
                  would no longer be necessary to achieve the purposes for which
                  it was collected. Any data that is no longer needed for
                  purposes specified in the “How We Use the Information
                  We Gather” section will be deleted after ninety (90) days.
                  Wallet addresses created through the IINTERSTELLAR™{" "}
                  application cannot be deleted from the Ethereum blockchain,
                  therefore we are unable to delete this personal information.
                  If at any point you wish for
                  IINTERSTELLAR™ to delete information about you, you may
                  contact us at Support@ IINTERSTELLAR.io. Request the
                  restriction of the processing of your personal data:
                  you may request that IINTERSTELLAR™ only processes your
                  personal data in limited circumstances, including with your
                  consent. Under California Consumer Protection Act
                  (“CCPA”) you also have the right not to be discriminated
                  against because of the exercise of your rights.
                  Please note that we do not sell your{" "} personal data.
                </p>
              </div>

              <div>
                <p className="head-label">Data retention</p>
                <p className="description-p2">
                  Please note that even If you delete your Wallet or addresses
                  from the IINTERSTELLAR™mobile application, uninstall
                  IINTERSTELLAR™ mobile applications from your device,
                  or request that your information be deleted, we still may
                  retain some information that you have provided to us to
                   maintain IINTERSTELLAR™ or to comply with the laws
                  and regulations to which IINTERSTELLAR™ is subject. If you
                  have any question or objection as to how we collect
                  and process your personal information, please contact:
                  Support@ IINTERSTELLAR.io.
                </p>
              </div>

              <div>
                <p className="head-label">Data security</p>
                <p className="description-p2">
                  We are committed to making sure your information is protected
                  in accordance with applicable laws and our data privacy
                  policies. We have selected third-party vendors that
                  use the Ethereum network, including Coinbase, Shapeshift, and
                  Changelly, that help us keep your Personal Information safe.
                  
                  Unfortunately, we donot control these third parties and
                  therefore cannot guarantee complete security. We work to
                  protect the security of your personal information
                  during transmission by using encryption protocols and
                  software. We maintain physical, electronic and procedural
                  safeguards in
                  connection with the collection, storage and disclosure of your
                  personal information and secure all connections with industry
                  standard transport layer security. Even with all
                  these precautions, we cannot fully guarantee against the
                  access, disclosure, alteration, or deletion of data through
                  events,
                  including but not limited to hardware or software failure or
                  unauthorized use. Any information that you provide to us is
                  done so entirely at your own  risk.
                </p>
              </div>

              <div>
                <p className="head-label">Children</p>
                <p className="description-p2">
                  We are especially sensitive about children's information. Our
                  Services are not targeted towards children, and our users must
                  be at least eighteen (18) years old to use our
                  services. We don't knowingly collect information from children
                  under the age of 13. If you are a parent or legal guardian of
                  a minor
                  child, we will treat any information that you provide us while
                  using IINTERSTELLAR™ on behalf of your minor child as Personal
                  Information as otherwise provided in this  Privacy
                  Policy. If you have questions concerning our information
                  practices with respect to children, or if you learn that a
                  child under the
                  age of 13 has used IINTERSTELLAR™, created a user account, or
                  provided us with personal information, please email us at
                  Support@ IINTERSTELLAR.io
                </p>
              </div>

              <div>
                <p className="head-label">
                  Conditions of use, notices, changes and updates to privacy
                  notice
                </p>
                <p className="description-p2">
                  If you choose to use IINTERSTELLAR™, your use and any dispute
                  over privacy is subject to this Notice and our Terms of Use.
                  If you have any concerns about privacy at
                  IINTERSTELLAR™, please contact us with a complete description,
                  and we will try to resolve it. You also have the right to
                  contact your local Data Protection Authority. We
                  reserve the right to update and revise this Privacy Notice at
                  any time. We occasionally review this Privacy Notice to
                   make sure it complies with applicable laws and
                  conforms to changes in our business. If we do revise this
                  Privacy Notice, we will update the “Effective Date”
                  at the top of this page so that you can tell if it has changed
                  since your last visit and will do our best to notify you.
                  Please review this Privacy Notice regularly to ensure
                  that you are aware of its terms. Any use of IINTERSTELLAR™
                  after an amendment to our Privacy Notice constitutes your
                   acceptance to the revised or amended terms.
                </p>
              </div>
            </div>
          </Col>
          {/* Footer */}
          <Row>
            <Col className="pp-footer">
              <Footer />
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  );
}
