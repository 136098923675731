import contractAbi from "../../utils/Contracts/contractAbi.json";
import approveAbi from "../../utils/Contracts/approve.json";

import {
  MintContract,
  ApprovedToken,
} from "../../utils/Contracts/ContractAddress";
import { useCallback } from "react";
export const useMintRead = ({ account }) => {
  const mintRead = useCallback(
    async (account, customweb3) => {
      const contract = new customweb3.eth.Contract(contractAbi, MintContract);
      try {
        const estimatedGas = await contract.methods
          .getMintedTokens(account)
          .estimateGas();

          const details = await contract.methods.getMintedTokens(account).call({
            gas: estimatedGas,
          });

        return details;
      } catch (error) {
        console.log(error, "errpr");
        throw error;
      }
    },
    [account]
  );
  return { mintRead: mintRead };
};
export default useMintRead;
