import { Row, Col, Container } from "react-bootstrap";
import React from "react";
import "./Tiles.css";
import Button from "react-bootstrap/Button";
import Navbar from "../../Components/Custom/Navbar/Navbar";
import Footer from "../../Components/Custom/Footer/Footer";
import TilesCard from "../../Components/Cards/Tiles-card/TilesCard";
import { useNavigate } from "react-router-dom";

export default function TilesAndProcedures() {
  const navigate = useNavigate();
  const TilesData = [
    {
      headtext: "Summary",
      subheadtext: "PROPERTY NFT - Sellable",
      bgimg: "/Images/Properties-FAQ/cards-bg.png",
      bodytext: [
        <p key={1} className="d-flex">
          Note: After your property purchase - you have the<br></br> opportunity
          to purchase a Citiizenship™.<br></br>
        </p>,
        <ul>
          <li>
            You can purchase a Property for $29 – and receive 1 hectare of land,
            and of course you can buy any property and any amount of properties.
          </li>
          <li>
            The purchase of a one-hectare property is prerequisite for the
            purchase of a Citiizenship™.
          </li>
          <li>
            If you purchase a Citiizenship™, a one-hectare property plot becomes
            non-sellable and becomes permanently associated with your
            Citiizenship. All other properties can be sold.
          </li>
        </ul>,
      ],
    },
    {
      subheadtext: "CITIZENSHIP™ NFT - Non Sellable",
      bodytext:
        "You can purchase your Citiizen™ for $49 – and receive $28 in M.A.R.S.™ tokens. Claimed M.A.R.S.™ tokens will be sent to your wallet. You can then use the $28 M.A.R.S.™ tokens to Register in the www.REALGifting.io platform.",
    },
    {
      headtext: "DETAILS",
      subheadtext: "PROPERTYSHIP",
      bgimg: "/Images/Properties-FAQ/cards-bg.png",
      bodytext: [
        <p key={1} className="d-flex">
          <p className="me-2">1. </p>
          Purchase Property for $49 - receive property as listed on OpenSea.
          <br></br>
          <br></br>
        </p>,
        <p key={2} className="d-flex">
          <p className="me-2">2. </p>
          The NFT recognizes the Property purchase so that a Citiizenship™ can
          then be purchased. Being a MARTIIAN™ Property owner entitles you to
          purchase a Martiian Citiizenship™.
          <br></br>
          <br></br>
        </p>,
        <p key={3} className="d-flex">
          <p className="me-2">3. </p>
          The Purchaser's wallet address or alpha numeric generated code becomes
          the identifier of the purchased Property plot that your Citiizenship™
          will be forever associated with, so this plot cannot be sold. All
          other additional plots can be sold. You can purchase more properties
          but cannot qualify for additional Citiizenships™.
          <br></br>
          <br></br>
        </p>,

        <p key={4} className="d-flex">
          <p className="me-2">4. </p>
          The purchase of a Property will entitle the purchaser to receive
          ownership of a plot of as listed on OpenSea.
        </p>,
      ],
    },
    {
      subheadtext: "CITIIZENSHIP",
      bgimg: "/Images/Properties-FAQ/cards-bg.png",
      bodytext: [
        <p key={1} className="d-flex">
          <p className="me-2">1. </p>
          The Citiizenship NFT recognizes the Property purchase so that the
          Citiizenship can then be purchased. A Citiizenship purchase is
          prevented until the Property has been previously purchased. The
          purchase of the one Property plot that cannot be sold is what the
          Citiizenship is forever tied to.
          <br></br>
          <br></br>
        </p>,
        <p key={2} className="d-flex">
          <p className="me-2">2. </p>
          Being a MARTIIAN Citiizen entitles a person to claim $28 in M.A.R.S.™
          tokens from the M.A.R.S.™ NFT platform.
          <br></br>
          <br></br>
        </p>,
        <p key={3} className="d-flex">
          <p className="me-2">3. </p>
          <p>
            The $28 in EQE can then be used to Register for the{" "}
            <a
              href="https://www.Get-REAL.io"
              alt="Get-REAL.io"
              className="a-custom"
            >
              www.Get-REAL.io
            </a>{" "}
            gifting platform (only if the User has not previously Registered due
            to the one account only policy).
            <br></br>
            <br></br>
          </p>
        </p>,
        <p key={4} className="d-flex">
          <p className="me-2">4. </p>
          Citiizenship™ has a unique alpha-numeric wallet address identifier for
          the Citiizen™.
          <br></br>
          <br></br>
        </p>,
        <p key={5} className="d-flex">
          <p className="me-2">5. </p>
          <p>
            Citiizenship™ has a certificate generated by the NFT - that has the
            unique Citiizen™ numeric identifier on it - and can be accessed only
            by the Purchaser.
          </p>
        </p>,
      ],
    },
    {
      subheadtext: "GUARDIIAN Wallet™",
      bodytext: [
        <p key={1} className="d-flex">
          {/* <p className="me-2">1. </p> */}
          If you use the GUARDIIAN Wallet™ to Register in the www.Get-REAL.io
          gifting Platform the Gifting Platform recognizes the GUARDIIAN Wallet™
          being used so that you can receive extra advantages as a bonus
          for using GUARDIIAN Wallet™.
        </p>,
      ],
    },
    {
      subheadtext: "GUARDIIAN Bridge",
      bodytext: [
        <div key={1} className="d-flex">
          {/* <p className="me-2">1. </p> */}
          <p>

          You will need to use the GUARDIIAN Bridge™ to convert your
          M.A.R.S.™ Tokens to BNB or BUSD to enter the R.E.A.L.™ Platform.
          Simply go to: <span><a style={{color: "white"}} href="https://www.guardiianbridge.io/">https://www.guardiianbridge.io/</a></span> and bridge your M.A.R.S.™ tokens.
          Make sure you watch the short instructional videos on the “HOW TO
          ENROLL” page: <span><a style={{color: "white"}} href="https://realgifting.io/">https://realgifting.io/</a></span>
          </p>
        </div>,
      ],
    },
  ];
  return (
    <>
      <Container fluid className="Tiles-contain">
        {/* <img
          src="\Images\Home\galaxy-bg-starts.svg"
          alt="img"
          className="img-fluid bg-home-starts"
        /> */}
        <Navbar />
        {/* Section-1 */}
        <Row>
          <Col className="sec1">
            <img
              src="/Images/Tiles-Procedures/Titles and Procedures.svg"
              alt="Tiles-&-Procedures"
              width={"100%"}
            />
            <div className="overlay-txt">
              <div>
                <p className="description-tiles">How to become MARTIIAN</p>
              </div>
              <div>
                <Button
                  type="button"
                  className="btn-Martian-tiles"
                  onClick={() => navigate("/home")}
                >
                  Be Martiian
                </Button>
              </div>
            </div>

            <div className="polygon-contain">
              <p
                className="poly-txt"
                onClick={() => {
                  window.location.href = "https://guardiianwallet.io/";
                }}
              >
                Install GUARDIIAN Wallet Here
              </p>
            </div>
          </Col>
        </Row>

        {/* Accordions Section */}
        <Row>
          <Col className="tiles-data-contain">
            {TilesData.map((item, index) => (
              <TilesCard
                key={index}
                headtext={item.headtext}
                subheadtext={item.subheadtext}
                bodytext={item.bodytext}
                bgimg={item.bgimg}
              />
            ))}
          </Col>
        </Row>
        {/* About Section */}
        <Row>
          <Col className="about-tiles-contain">
            <img
              src="/Images/Home/about-mars.svg"
              alt="About"
              className="img-fluid set-custom-width"
            />
          </Col>
        </Row>

        <Row>
          <Col className="Tiles-footer">
            <Footer />
          </Col>
        </Row>
      </Container>
    </>
  );
}
