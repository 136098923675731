"use client";
import { Modal } from "react-bootstrap";
import "./wallet.css";
// import crossmodal from "../../../assets/cross-modal.svg";
// import Image from "next/image";
import { useState, useEffect } from "react";
// import { P } from "../../Custom/Typography/Typography";
import Signature from "../../hooks/Web3Connection/userSign";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../hooks/Web3Connection/useAuth";
// import { useTheme } from "@/utils/themecontext/ThemeProvider";
import { ErrorToast, SuccessToast } from "../toast/Toast";
import Cookies from "js-cookie";
export default function Web3Modal(props) {
  const { account, chainId } = useWeb3React();
  const { userSign } = Signature();
  const [log, setLog] = useState(false);
  const { login } = useAuth();
  //   const theme = useTheme();

  const trustWallet = async () => {
    try {
      console.log("i am commin in the walletConnect");
      props.setModalShows(false);
      await login("walletconnect", 137);
      localStorage.setItem("connectorId", "walletconnect");
      localStorage.setItem("flag", "true");
      setLog(true);
    } catch (err) {
      console.log("err", err);
    }
  };
  const gaurdianWallet = async () => {
    try {
      props.setModalShows(false);
      await login("injected", 137);
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
      setLog(true);
    } catch (err) {
      console.log("err", err);
    }
  };

  const connectMetaMask1 = async () => {
    try {
      props.setModalShows(false);
      await login("injected", 137);
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
      setLog(true);
    } catch (err) {
      console.log("err", err);
    }
  };

  const loginUser = async () => {
    if (account) {
      const res0 = await userSign(account);
      Cookies.set("signature", res0, {
        secure: true,
      });
      if (account && res0) {
        SuccessToast("User Signature Successfull !");
      }
    } else {
      ErrorToast("Wallet not Connected");
    }
  };

  useEffect(() => {
    if (account && log) {
      loginUser();
      setLog(false);
    }
  }, [account, log]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-whole"
      show={props.show}
      onHide={() => props.setModalShows(false)}
    >
      <Modal.Header
        style={{ background: "black", border: "1px solid red", color: "white"}}
        className="main-section-wallet"
      >
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        <img
          src={"/Images/yourproperty/cross.svg"}
          className="close-btn-img"
          onClick={() => props.setModalShows(false)}
        />
      </Modal.Header>
      <Modal.Body
        style={{
          background: "black",
          border: "1px solid red",
          borderTop: "none",
          color: "white",
        }}
        className="main-section-wallet"
      >
        <div >
          <p className="connect-p">Connect Your Wallet</p>
          <p className="subText">
            {/* Korem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
            turpis molestie, <br /> dictum est a, mattis tellus. Sed dignissim,
            metus nec fringilla accumsan, risus sem <br /> sollicitudin lacus,
            ut interdum tellus elit sed risus. */}
          </p>
          <div className="choose-txt">
            <p
            // fontSize={"12px"}
            // fontWeight={"600"}
            // lineHeight={"16.26px"}
            // color={"#656565"}
            >
              {/* Kindly choose from options below: */}
            </p>
          </div>
          <div className="wallets-contain meta-cards">
            <div className="meta-diff">
              <img
                alt="connect wallet"
                src={"/Images/yourproperty/walletConnect.svg"}
                onClick={trustWallet}
              />
            </div>
            <div className="meta-common">
            <a href="https://guardiianwallet.io/" target="_blank" rel="noopener noreferrer">

              <img
                src={"Images/yourproperty/gaurdian.svg"}
                alt="gaudian"
                onClick={gaurdianWallet}
              />
              </a>
            </div>
            <div className="meta-common">
              <img
                src={"Images/yourproperty/metamask.svg"}
                alt="polygon"
                onClick={connectMetaMask1}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
