import Accordion from "react-bootstrap/Accordion";
import "./FAQCard.css";
function FAQCard({LHSimage,heading,bodycontent,bgimg}) {
  const defaultImg = "/Images/Home/homeinfo-card-bg.svg";
  const backgroundImageStyle = {
      backgroundImage: `url(${bgimg || defaultImg})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top center",
      backgroundSize:"cover"
  };
  return (
    <Accordion defaultActiveKey="1" flush className="FAQ-card" style={backgroundImageStyle}>
      <Accordion.Item eventKey="0">
        <Accordion.Header  className="FAQ-head-contain">
            
            <img src={LHSimage} alt="whymars" className="FAQ-mars-img"/>
            <p className="head-txt">{heading}</p>
        </Accordion.Header>
        <Accordion.Body className="FAQ-card-body">
          {bodycontent}
        </Accordion.Body>
      </Accordion.Item>

     
    </Accordion>
  );
}

export default FAQCard;
