import React from "react";
import {Row, Col,Container} from "react-bootstrap";
import "./Footer.css"
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Footer(){
    const navigate = useNavigate();
    return(
        <>
        <Row>
            <Col>
                {/* <div className="links-contain" >
                    <Link to="/termofuse" style={{textDecoration : 'none'}}>
                    <p className="footer-link">Terms & Conditions</p>
                    </Link>
                    <Link to="/PrivacyPolicy" style={{textDecoration : 'none'}}>
                    <p className="footer-link">Privacy Policy</p>
                    </Link>
                </div> */}
                <div>
              <div className="d-flex justify-content-center gap-3 pb-5">
                <p
                  onClick={() => navigate("/termofuse")}
                  className="term-footer"
                >
                  Terms & Conditions
                </p>
                <p
                  className="term-footer"
                  onClick={() => navigate("/PrivacyPolicy")}
                >
                  Privacy Policy
                </p>
              </div>
              <div></div>
            </div>
                <div className="footer-copyright">
                    <img src="/Images/Navbar/Footer-copyright.svg" alt="Copyright"   className="copyr-img"/>
                </div>
            </Col>
        </Row>
        
        </>
    );
}