import contractAbi from "../../utils/Contracts/contractAbi.json";
import approveAbi from "../../utils/Contracts/approve.json";

import {
  MintContract,
  ApprovedToken,
} from "../../utils/Contracts/ContractAddress";
import { useCallback } from "react";
export const useMint = ({ account }) => {
  const mintHook = useCallback(
    async (account, customweb3, plotId, baseUri, values) => {
      const contract = new customweb3.eth.Contract(contractAbi, MintContract);
      const approvecontract = new customweb3.eth.Contract(
        approveAbi,
        ApprovedToken
      );

      try {
        const fee1=29 * values
        const feeinString=fee1.toString()
        const valuesend = customweb3.utils.toWei(feeinString, "ether");
     
        const approveestimatedGas = await approvecontract.methods
          .approve(MintContract, valuesend)
          .estimateGas({
            from: account,
          });

        const approve = await approvecontract.methods.approve(MintContract, valuesend).send({
          from: account,
          gas: approveestimatedGas,
        });
        
        const estimatedGas = await contract.methods
        .mint(plotId,values)
        .estimateGas({
          from: account,
        });

        const details = await contract.methods
          .mint(plotId,values)
          .send({
            from: account,
            gas: estimatedGas,
            
          });

        return details;
      } catch (error) {
        console.log(error, "errpr");
        throw error;
      }
    },
    [account]
  );
  return { mintHook: mintHook };
};
export default useMint;
