import React from "react";
import "./About.css";
import { Row, Col, Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Navbar from "../../Components/Custom/Navbar/Navbar";
import Footer from "../../Components/Custom/Footer/Footer";
import AboutCard from "../../Components/Cards/About-card/AboutCard";
import ReactPlayer from "react-player";
import Accordion from '../../Components/Cards/Accordion/Accordion';
import { useNavigate } from "react-router-dom";

export default function About() {

  const navigate = useNavigate();
  const videoUrl = "https://youtu.be/V9cCE7E-zzk";
  const accordionsData = [
    {
      headimg: "/Images/Home/Why-Mars.svg",
      headtext: "The BIIO Metaverse",
      bodytext:
        "M.A.R.S.™ is the gateway into the BIIO Metaverse™, an entirely revolutionary and distruptive P2P community that is rapidly evolving into the premier “Place-to-be”, providing services such as exchanges, bridges, gaming, DeFi, NFTs, and much more.",
    },
    {
      headimg: "/Images/Home/img-why2.svg",
      headtext: "M.A.R.S.™ Staking program",
      bodytext:
        "M.A.R.S.™ intends to provide a staking program that aims to build a strong and committed community while providing numerour perks and benefits to its users.  Some of the benefitis higher launchpad access and exclusive entry into special groups and the opportunity to participate in seed and private deals.",
    },
    {
      headimg: "/Images/Home/img-why1.svg",
      headtext: "EQUIIUS™ “E” Token (EQE)",
      bodytext:
        "EQUIIUS™ “E” token (EQE) plays a pivotal role in the BIIOVerse™ Super App, serving as the key to unlocking a myriad of features and utilities within the platform.EQUIIUS™ “E” token (EQE) is establishing itself as a major player in the blockchain and cryptocurrency space, drawing more and more holders of the crypto into the BIIO Metaverse™.",
    },
    {
      headimg: "/Images/Home/img-why1.svg",
      headtext: "MARS Token (M.A.R.S.™))",
      bodytext:
        "The M.A.R.S. ™ token is your ticket to being a proud property owner onthe second most inhabitable planet in our Solar system. Not only that, it entitles you to the benefits of MARTIIAN CITIZENSHIP™ and enables you to Enter Into the R.E.A.L. platform, “Where good things happen.”",
    },
    {
      headimg: "/Images/Home/img-why2.svg",
      headtext: "M.A.R.S.™ GAMIING",
      bodytext:
        "The M.A.R.S.™ NFT will link to the M.A.R.S.™ Gamiing platform.  That's the reason for the large number of plots.  Normally an NFT has a small number of plots that can be resold at profit.  The Gamiing platform will also allow for that, and much more!  So get your properties now, because whoever lands on them are going to have to pay you a toll or other fees that will make your properties quite valuable indeed!",
    },
  ];
  return (
    <>
      <Container fluid className="About-contain">
        {/* <img
          src="\Images\Home\galaxy-bg-starts.svg"
          alt="img"
          className="img-fluid bg-home-starts"
        /> */}
        <Navbar About={true} />
        {/* Section -1 */}
        <Row className="About-main-row">
          <Col className="About-sec1">
            <img
              src="/Images/About/sec1.svg"
              alt="About"
              width={"100%"}
              height={"100%"}
              className="About-sec1-img"
            />

            <div>
              <p className="description-head-about">Why the BIIOVerse?</p>
              <p className="description-subhead-about">
                Because the BIIOVerse PAYS YOU to be there! And BIG! So why be
                anywhere else?
              </p>
            </div>
            <div>
              <div className="yourpropertybottom">
                <ReactPlayer
                  className="aboutvideoplayers"
                  url={videoUrl}
                  controls={true}
                />
              </div>
              <Button type="button" className="btn-BeThere" onClick={() => navigate('/yourproperty')}>
                Be There Now
              </Button>
            </div>
            
            <div className="polygon-context">
            <div className="ethereum-contain">
              <img
                src="/Images/About/etherum.svg"
                alt="Ethereum"
                className="ethereum-img"
              />
              <p className="ethereum-txt">Built with Ethereum</p>
            </div>
              <div className="poly-bg">
              <p className="poly-txt" onClick={ () => {window.location.href="https://guardiianwallet.io/"} }>Install GUARDIIAN Wallet Here</p>
              </div>
            </div>
          </Col>
        </Row>
        {/* Accordion Section */}
        {/* <Row>
          <Col className="about-data-contain">
            {AboutData.map((accordion, index) => (
              <Accordion
                key={index}
                headimg={accordion.headimg}
                headtext={accordion.headtext}
                bodytext={accordion.bodytext}
              />
            ))}
          </Col>
        </Row> */}
        <Row>
          <Col className="accordion-contain">
            {accordionsData.map((accordion, index) => (
              <Accordion
                key={index}
                imglogo={accordion.headimg}
                headtxt={accordion.headtext}
                bodytxt={accordion.bodytext}
              />
            ))}
          </Col>
        </Row>
        {/* About Section */}
        
        {/* Footer Section */}
        <Row>
          <Col className="About-footer">
            <Footer />
          </Col>
        </Row>
      </Container>
    </>
  );
}
