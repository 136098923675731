import React, { useEffect, useState } from "react";
import "./yourproperty.scss";
import Navbar from "../../Components/Custom/Navbar/Navbar";
import ReactPlayer from "react-player";
import Footer from "../../Components/Custom/Footer/Footer";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import useMint from "../../hooks/Contract/useMint";
import useWeb3 from "../../hooks/Web3Connection/useWeb3";
import Web3Modal from "../../Components/modal/Web3Modal";
import { ErrorToast } from "../../Components/toast/Toast";

const Plotdetail = () => {
  const videoUrl = "https://youtu.be/u59lISCVe2A";
  const [click1, setclick1] = useState(true);
  const [click2, setclick2] = useState(true);
  const [open, setOpen] = useState(false);
  const { account, chainId } = useWeb3React();
  const [startLength, setStartLength] = useState(6);
  const { mintHook } = useMint({ account });
  const customweb3 = useWeb3();

  useEffect(() => {
    const handleResize = () => {
      const newStartLength = window.innerWidth >= 600 ? 11 : 6;
      setStartLength(newStartLength);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const truncateWalletAddress = (address, endLength = 6) => {
    const start = address.slice(0, startLength);
    const end = address.slice(-endLength);
    return `${start}..............${end}`;
  };

  const handleContract = async () => {
    const plotId = 20;
    const baseUri = "asfsgdfgdfdf";
    if (!account) {
      setOpen(true);
      return;
    }

    try {
      const hash = await mintHook(account, customweb3, plotId, baseUri);
      if (hash) {
        console.log("buyed successfully");
      }
    } catch (error) {
      ErrorToast(error.message);
      console.log("this is the error", error.originalError);
    }
  };

  const walletAddress1 =
    "0x228c1ed4521c55684584714255415584125541524152552145215451";
  return (
    <>
      <section className="yourproperty">
        <span className="backgroundimg"></span>
        <Navbar Plotdetail={true} />
        <div className="theme-container">
          {click2 ? null : (
            <img
              src="\Images\yourproperty\citizenshiptext.png"
              alt="citizenshiptext"
              className="citizenshiptext"
            />
          )}
          <div
            className={
              click2 ? "yourpropertyinner" : "yourpropertyinner paddtop"
            }
          >


            <div className="ethereum-contain-FAQ">
              <img
                src="/Images/About/etherum.svg"
                alt="Ethereum"
                className="ethereum-img-FAQ"
              />
              <p className="ethereum-txt-FAQq">Built with Ethereum</p>
            </div>

            <div className="propertyleft">
              <Link to="/yourproperty" style={{ textDecoration: "none" }}>
                <button className="backbtn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 12.5C0 19.4036 5.59644 25 12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5ZM10.3779 7.12853L6.10113 11.4055C5.53305 11.9734 5.51748 12.927 6.08984 13.4996L10.3666 17.7762C11.7191 19.1286 13.821 17.0427 12.4717 15.6936L10.7166 13.9386L17.603 13.9386C19.5104 13.9386 19.4937 10.9771 17.5817 10.9771L10.7172 10.9771L12.4605 9.2336C13.8129 7.88113 11.727 5.77923 10.3779 7.12853Z"
                      fill="white"
                    />
                  </svg>
                  Back
                </button>
              </Link>
              <div className="propertyleftupper">
                <div className="listingcard ownercard">
                  <div className="plotmainhead">
                    <h6 className="plotmainheading">Plot 291</h6>
                  </div>
                  <div className="plotbottom">
                    <h6 className="plottophead">Wrapped Plot</h6>
                    <div className="plotownermain">
                      <div className="plotownerimg">
                        <img
                          src="\Images\Home\about-mars.svg"
                          alt="innerimg"
                          className="innerimg"
                        />
                      </div>
                      <div className="plotownertext">
                        <h6 className="ownerhead">Owned by</h6>
                        <p className="walletmain">
                          {truncateWalletAddress(walletAddress1)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="redbtn-div">
                  <button className="redbtn" onClick={() => handleContract()}>
                    Purchase
                  </button>
                </div>
                {/* {click1 ? (
                  <button className="redbtn" onClick={() => setclick1(false)}>
                    View on Opensea
                  </button>
                ) : (
                  <button className="redbtn" onClick={() => setclick2(false)}>
                    Purchase
                  </button>
                )} */}
              </div>
            </div>
            
            <div className="propertyright">
            <div className="image__ts">
          <img src="/Images/Properties-FAQ/image-21.svg" alt="360" />
        </div>
              {click2 ? (
                <img
                  src="\Images\yourproperty\mapimg.png"
                  alt="propertymapimg"
                  className="propertymapimg img-fluid"
                />
              ) : (
                <img
                  src="\Images\yourproperty\certificate.png"
                  alt="propertymapimg"
                  className="propertymapimg img-fluid"
                />
              )}

              <span className="builtmain" onClick={ () => {window.location.href="https://guardiianwallet.io/"} }>
                {/* <img src="\Images\yourproperty\dollarimg.png" alt="dollarimg" className="dollarimg" /> */}
                Install GUARDIIAN Wallet Here
              </span>
            </div>
          </div>
          <Footer />
        </div>
      </section>
      <Web3Modal show={open} setModalShows={setOpen} />
    </>
  );
};

export default Plotdetail;
