import React from "react";
import "./CitizenshipFAQ.css";
import { Row, Col, Container } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Navbar from "../../Components/Custom/Navbar/Navbar";
import Footer from "../../Components/Custom/Footer/Footer";
import CitizenshipCard from "../../Components/Cards/Citizenship-card/Citizenshipcard";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function CitizenshipFAQ() {

  const navigate = useNavigate();

  const videoUrl2 = "https://youtu.be/f8-c75CxqIY";
  const CitizenshipCardData = [
    {
      LHSimage: "/Images/Home/Why-Mars.svg",
      heading: "What do you get?",
      bodycontent:
        "It all starts with your CITIZENSHIP™ Certificate and 28 EQE tokens that you can redeem in the Next generation R.E.A.L. Technology Platform. https://Get-REAL.io/",
    },
    {
      LHSimage: "/Images/Home/img-why2.svg",
      heading: "Documents  and More",
      bodycontent:
        "IInterstellar™ provides complete documentation of your legal CITIZENSHIP™, such as IInterstellar™ Passports and IInterplanetary IIdentification™.",
    },
    {
      LHSimage: "/Images/Home/img-why1.svg",
      heading: "Process and unProcess",
      bodycontent:
        "A CITIZENSHIP™ can be renounced, i.e., relinquished or given up, just like in the Earth world.  There's even a legal form and proceedure for that. However, the loss of Martiian™ nationality is an irrevocable act, and non-Martiians could no longer enjoy Martiian™ benefits.  But why would anyone want to do that!?",
    },
    {
      LHSimage: "/Images/Home/img-why2.svg",
      heading: "How does it work?",
      bgimg: "/Images/Properties-FAQ/card-lg-bg.png",

      bodycontent: [
        <p key={1}>
          IINTERSTELLAR™.io is a fully-functioning decentralized
          Citiizenship/Propertyship exchange built on top of Ethereum smart
          contracts. This is one of the first semi-fungible
          citiizenship/propertyship tokens (NFTs) ever created.<br></br>
          <br></br> To qualify for Martiian Citiizenship™ all you need to do is
          firstly purchase a minimum one hectar Martiian plot (see PROPERTIES
          FAQ), and then for a small modest fee simply apply for Martiian
          Citiizenship™.
        </p>,
      ],
    },
  ];
  return (
    <>
      <Container fluid className="Citizenship-contain">
      {/* <img src="\Images\Home\galaxy-bg-starts.svg" alt="img" className="img-fluid bg-home-starts" /> */}
        <Navbar About={true} />
        <Row>
          <Col className="citizenship-sec1">
            <img
              src="/Images/Properties-FAQ/Sec1-citizenship.svg"
              alt="Citizenship-Properties"
              width={"100%"}
            />
            <div className="yourpropertybottoms">
              <ReactPlayer className="aboutvideoplayers" url={videoUrl2} controls={true} />
            </div>
            <div>
              <p className="description-head-Citizenship">Why Citiizenship?</p>
              <p className="description-subhead-Citizenship">
                Because being an IInterstellar CITIZENSHIP™ has many rights,
                advantages, and benefits, such as “dual citizenship” priviliges
                and immunities, just like in the Earth world.
              </p>
            </div>
            <div className="btn-register">
            <Link to="/Certificate" style={{textDecoration : 'none'}}>
              <Button type="button" className="btn-Register" >
                Register for Citiizenship
              </Button>
              </Link>
            </div>

            
            <div className="polygon-context">
            <div className="ethereum-contain-citizenship">
              <img
                src="/Images/About/etherum.svg"
                alt="Ethereum"
                className="ethereum-img-citizenship"
              />
              <p className="ethereum-txt-citizenship">Built with Ethereum</p>
            </div>
            <div className="poly-bg">
              <p className="poly-txt" onClick={ () => {window.location.href="https://guardiianwallet.io/"} }>Install GUARDIIAN Wallet Here</p>
              </div>
            </div>
          </Col>
        </Row>
        {/* Accordion Section */}
        <Row>
          <Col className="Citizenship-data-contain">
            {CitizenshipCardData.map((item, index) => (
              <CitizenshipCard
                key={index}
                heading={item.heading}
                LHSimage={item.LHSimage}
                bodycontent={item.bodycontent}
                bgimg={item.bgimg}
              />
            ))}
          </Col>
        </Row>

        {/* About Section */}
        <Row>
          <Col className="about-Citizenship-contain">
            <img src="/Images/Home/about-mars.svg" alt="About" className="img-fluid set-custom-width" />
          </Col>
        </Row>
        <Row>
          <Col className="Citizenship-footer">
            <Footer />
          </Col>
        </Row>
      </Container>
    </>
  );
}
