import React, { useState, useEffect } from "react";
import "./yourproperty.scss";
import Navbar from "../../Components/Custom/Navbar/Navbar";
import ReactPlayer from "react-player";
import Footer from "../../Components/Custom/Footer/Footer";
import { Link } from "react-router-dom";
import MarsTexture from "../../Components/Mars/MarsTexture";
import MarsScene from "../../Components/Mars/MarsTexture";
import ScreenLoader from "../../Components/loader/ScreenLoader";
import { useSelector } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import useMint from "../../hooks/Contract/useMint";
import useWeb3 from "../../hooks/Web3Connection/useWeb3";
import Web3Modal from "../../Components/modal/Web3Modal";
import { ErrorToast, SuccessToast } from "../../Components/toast/Toast";
import { useMediaQuery } from "react-responsive";

// import Plotdetail from "./Plotdetail";

const Yourproperty = () => {
  const videoUrl4 = "https://youtu.be/f6zBXC46OPU";
  const [showLoader, setShowLoader] = useState(true);
  const [loader, setLoader] = useState(false);
  const { account, chainId } = useWeb3React();
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState(1);

  const { mintHook } = useMint({ account });
  const customweb3 = useWeb3();
  const count = useSelector((state) => state?.plot?.plot);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 5000); // Hide loader after 30 seconds
  }, []);
  const handleContract = async () => {
    const plotId = 20;
    const baseUri = "asfsgdfgdfdf";
    if (!account) {
      setOpen(true);
      return;
    }
    if (!count) {
      ErrorToast("Please Select a plot");
      return;
    }
    setLoader(true);

    try {
      const hash = await mintHook(account, customweb3, count, baseUri, values);
      if (hash) {
        SuccessToast("Hurra! You Successfully Buy a plot on mars");
        setLoader(false);
      }
    } catch (error) {
      ErrorToast(error.message);
      setLoader(false);
      console.log("this is the error", error.originalError);
    }
  };

  return (
    <>
      {/* {showLoader && <ScreenLoader />} */}
      {loader && <ScreenLoader />}
      {/* <ScreenLoader /> */}
      <section className="yourproperty">
        <span className="backgroundimg"></span>
        <Navbar Yourproperty={true} />
        <div className="theme-container">
          <div className="yourpropertyinner urproperty-container">
            <div className="marspropty-container">
              <div className="propertyleft">
                <div className="propertyleftupper">
                  <h6 className="propertylefthead">Mint Property</h6>
                  <div className="propertyleftcard ">
                    <h6 className="cardhead">Welcome aboard!</h6>
                    <p className="cardpara">
                      Get started by selecting a M.A.R.S.™ <br></br>Plot on the
                      map to the right.
                    </p>
                  </div>

                  <div>
                    <h6 className="propertylefthead">Details</h6>

                    <div className="propertyleftcard ">
                      {/* <h6 className="cardhead">Plot Details</h6> */}
                      <p className="cardpara cardparanew">
                        Plot Id : {count ? count : "Please select a plot"}
                      </p>
                      <div className="property">
                        <div className="display">
                          <div className="price-txt">Price</div>

                          <div className="price-div">
                            <img
                              src="/Images/yourproperty/crypto.svg"
                              alt="Ethereum"
                              className="price-img"
                            />{" "}
                            {(29 * values)?.toFixed(2)}
                          </div>
                        </div>
                        <div className="display">
                          <div className="price-txt">Quantity</div>
                          <div className="quantity">
                            <div
                              onClick={() => {
                                if (values > 1) {
                                  setValues(values - 1);
                                }
                              }}
                              className="decrement"                            >
                              -
                            </div>
                            <div className="quaDisplay">{values}</div>
                            <div
                              onClick={() => {
                                setValues(values + 1);
                              }}
                              style={{ cursor: "pointer" }}
                              className="increment"
                            >
                              +
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <Plotdetail/> */}
                  <div className="redbtn-div">
                    <button className="redbtn" onClick={() => handleContract()}>
                      Purchase
                    </button>
                  </div>
                </div>
              </div>

              <div className="propertyright">
                <div className="marstexture">
                  <div className="degree">
                    <img
                      src="/Images/yourproperty/degree.svg"
                      alt="Ethereum"
                      className="ethereum-img-FAQ"
                    />
                  </div>
                  <MarsTexture />
                </div>
                {!isMobile && (
                  <div>
                    <div className="degree-cont">
                      <img
                        src="/Images/About/etherum.svg"
                        alt="Ethereum"
                        className="ethereum-img-FAQ"
                      />
                      <p className="ethereum-txt-FAQq">Built with Ethereum</p>
                    </div>
                    <div
                      className="builtmain install-web"
                      onClick={() => {
                        window.location.href = "https://guardiianwallet.io/";
                      }}
                    >
                      Install GUARDIIAN Wallet Here
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="yourpropertybottom">
              <ReactPlayer
                className="videoplayers"
                url={videoUrl4}
                controls={true}
              />
            </div>
            <div className="footer-property">
              <Footer />
            </div>
          </div>
        </div>
      </section>

      <Web3Modal show={open} setModalShows={setOpen} />
    </>
  );
};
export default React.memo(Yourproperty);
