import { Row, Col, Container } from "react-bootstrap";
import "./TokenClaim.css";
import Button from "react-bootstrap/Button";
import Navbar from "../../Components/Custom/Navbar/Navbar";
import Footer from "../../Components/Custom/Footer/Footer";
import useWeb3 from "../../hooks/Web3Connection/useWeb3";
import { useWeb3React } from "@web3-react/core";
import useGetPlot from "../../hooks/Contract/useGetPlot";
import { useEffect, useState } from "react";
import ScreenLoader from "../../Components/loader/ScreenLoader";
import useCitizenshipClaim from "../../hooks/Contract/useClaimToken";
import { ErrorToast, SuccessToast } from "../../Components/toast/Toast";
import Web3Modal from "../../Components/modal/Web3Modal";

export default function TokenClaim() {
  const { account } = useWeb3React();
  const customweb3 = useWeb3();
  const { plotRead } = useGetPlot({ account });
  const [selectedFraction, setSelectedFraction] = useState(null);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const { citizenClaimHook } = useCitizenshipClaim({ account });

  const handleGetPlot = async () => {
    setLoader(true);
    try {
      const hash = await plotRead(account, customweb3);
      // setCertificateUpdate(true)
      setSelectedFraction(hash[0]);

      console.log("this is hash data,", hash);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (account) {
      handleGetPlot();
    }
  }, [account]);

  const handleContract = async () => {
    if (!account) {
      setOpen(true);
      return;
    }

    setLoader(true);

    try {
      const hash = await citizenClaimHook(
        account,
        customweb3,
        selectedFraction
      );
      if (hash) {
        SuccessToast("Token claim successfully");
        setLoader(false);
      }
    } catch (error) {
      ErrorToast(error.message);
      setLoader(false);
      console.log("this is the error", error.originalError);
    }
  };
  return (
    <>
      {loader && <ScreenLoader />}
      <Container fluid className="terms-contain">
        {/* <img src="/Images/Home/Mars-bg.png" alt="img" className="img-fluid bg-home-starts" /> */}
        <Navbar />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src="/Images/Token-claim/toplogo.svg"
            alt=""
            className="token-logo token-claim-img-top"
          />
        </div>

        <Row>
          <Col className="sec-head p-0">
            <div>
              <div>
                <div>
                  <h1 className="terms-contain-citizen-h">
                    CITIIZENSHIP TOKEN CLAIM
                  </h1>
                </div>
                <div className="token-image">
                  <img
                    src="/Images/Token-claim/certificate.svg"
                    alt=""
                    className="token-claim-img"
                  />

                  {selectedFraction && selectedFraction !== "0" && (
                    <div className="plot-main-claim">
                      <h3
                        className="certificatio-noValue"
                        style={{ color: "black", margin: "0px" }}
                      >
                        Certificate {selectedFraction}
                      </h3>
                    </div>
                  )}
                </div>
                {selectedFraction && selectedFraction !== "0" && (
                  <div>
                    <p className="terms-contain-citizen-p">
                      You are eligible for token claim for minting IINTERSTELLAR
                      CITIIZENSHIP
                    </p>
                    <p className="terms-contain-citizen-p">
                      Claim tokens = 28 $MARS
                    </p>
                  </div>
                )}
              </div>

              {selectedFraction === null ? (
                <>
                  <div className="token-container">
                    <Button onClick={()=>{setOpen(true)}} type="button" className="btn-claim">
                      Connect
                    </Button>
                  </div>
                </>
              ) : (
                selectedFraction &&
                selectedFraction !== "0" && (
                  <div className="token-container">
                    <Button onClick={handleContract} type="button" className="btn-claim">
                      CLAIM
                    </Button>
                  </div>
                )
              )}
            </div>
          </Col>
        </Row>
        {/* PrivacyPolicy content */}
        <Row>
          {/* Footer */}
          <Row>
            <Col className="pp-footer">
              <Footer />
            </Col>
          </Row>
          <div className="token-install">
            <p
              className="token-txt"
              onClick={() => {
                window.location.href = "https://guardiianwallet.io/";
              }}
            >
              Install GUARDIIAN Wallet Here
            </p>
          </div>
        </Row>
      </Container>
      <Web3Modal show={open} setModalShows={setOpen} />
    </>
  );
}
