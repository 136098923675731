
import { configureStore } from "@reduxjs/toolkit";
import plotslice from "./reducer/plotslice";


export const store = configureStore({
  reducer: {
    plot:plotslice ,

  },
});
