import React from "react";
import "./PropertiesFAQ.css";
import { Row, Col, Container } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Navbar from "../../Components/Custom/Navbar/Navbar";
import Footer from "../../Components/Custom/Footer/Footer";
import FAQCard from "../../Components/Cards/PropertiesFAQ-Card/FAQCard";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';


export default function PropertiesFAQ() {
  const videoUrl1 = "https://youtu.be/xQNnSdNgUPs";
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const FAQCardData = [
    {
      LHSimage: "/Images/Home/Why-Mars.svg",
      heading: "Documents and More",
      bgimg: "/Images/Properties-FAQ/cards-bg.png",
      bodycontent: [
        <p key={1} className="d-flex">
          <p className="me-2 p-0">1. </p>
          Because owning IINTERSTELLAR™ property enables you to claim your
          stake, or stake your claim and earn both rewards and IInterstellar
          related currencies.<br></br>
          <br></br>
        </p>,
        <p key={2} className="d-flex">
          <p className="me-2 p-0">2. </p>
          Property ownership has many rights, advantages, and benefits, such as
          providing for “dual citiizenship” priviliges and immunities, just like
          in the Earth world.
        </p>,
        <p key={3} className="d-flex">
          <p className="me-2 p-0">3. </p>
          IINTERSTELLAR™ provides complete documentation of your Property
          ownership in the form of an NFT property title or deed listed on the
          Open Sea public domain https://opensea.io/OpenSea is a non-fungible
          token (NFT) marketplace where users can buy, sell, or create NFTs. It
          is a non-custodial platform, allowing users full control and access to
          their cryptocurrency wallets. Users interact directly with each other
          to buy or sell NFTs individually or in bundles.
        </p>,
        <p key={4} className="d-flex">
          <p className="me-2 p-0">4. </p>A Property can be sold, rented, or
          staked. There’s even a simple smart contract proceedure for that. If
          the property is built upon, it’s worth even more (M.A.R.S. Gaming to
          come).
        </p>,
        <p key={5} className="d-flex">
          <p className="me-2 p-0">5. </p>Property ownership is your ticket to
          M.A.R.S. Citiizenship, which subsequently entitles you to membership
          in the BIIO Metaverse, “Where good things happen”, (see next section).
        </p>,
        <p key={6} className="d-flex">
          <p className="me-2 p-0">6. </p>
          M.A.R.S. Gamiing is all about Property ownership. If someone wants to
          cross over your property they must pay a toll - and this could make
          you rich!
        </p>,
      ],
    },
    {
      LHSimage: "/Images/Home/img-why2.svg",
      heading: "How does it work?",
      bgimg: "/Images/Properties-FAQ/card-lg-bg.png",

      bodycontent: [
        <p key={1}>
          {" "}
          IINTERSTELLAR™.io is a fully-functioning decentralized real estate
          exchange built on top of Ethereum smart contracts. This is one of the
          first non-fungible citizenship/propertyship NFTs ever created. Each
          Martiian plot corresponds to a unique sector of Mars, and has a price
          set by IINTERSTELLAR™ (or the owner) upon purchase. You can purchase
          any amount of plots you want.<br></br>
          <br></br>Purchasing a plot entitles you to purchase a Citiizenship and
          receive $28 in M.A.R.S. tokens tokens that can be redeemed for a
          membership in the R.E.A.L.™ platform https://Get-REAL.io/. There is a
          one Citiizenship limit per person. (See CITIIZENSHIP FAQ section).
          <br></br>
          <br></br>Most people purchase property as an investment. Each real
          estate investment option allows an investor to purchase various types
          of property for as low as $49 a hectare (one hectare equals 1.47
          acres).
        </p>,
      ],
    },
    {
      LHSimage: "/Images/Home/Why-Mars.svg",
      heading: "How many Mars deed are available?",
      bgimg: "/Images/Properties-FAQ/card-lg-bg.png",

      bodycontent: [
        <p key={1}>
          Short answer – into the hundreds of millions. Definitely enough for
          everyone. The surface of the planet Mars is 144.4 million km². The
          IInterstellar business model divides it into 450 triangulated parts or
          quadrants. Each one is subdivided into smaller parts until finally we
          arrive at hectare size areas. You can easily purchase one or more. The
          purchase limit is 10 hectares per transaction. <br></br>
          <br></br> Property owners can build upon, rent, or sell the property
          after holding for a minimum of one year. To remain a Martiian Citizen
          a property must always be owned, therefore to sell a property you must
          own enough property remaining in your account to enable selling
          without renouncing or forfeiting your Martiian Citizenship.<br></br>
          <br></br> Currently, the land plots are arbitrarily provided within
          the specific quadrant chosen by the purchasing party and are provided
          on a first-come / first-served basis, meaning that those who purchase
          first will be given the opportunity to choose their specific plot when
          this site has the capability to show super close-up zoom.
        </p>,
      ],
    },
    {
      LHSimage: "/Images/Home/img-why2.svg",
      heading: "How do I use this website?",
      bodycontent:
        "You first need to install GUARDIIAN Wallet™ (or you can use any wallet, but without GUARDIIAN™ benefits). Then click on 'Be Martian' and allow GUARDIIAN Wallet™ to connect to the site when prompted. Now you'll be able to explore Martian properties by clicking and dragging the 3D Mars model. You can also view the plots of land that you currently own on your profile.",
    },
    {
      LHSimage: "/Images/Home/img-why2.svg",
      heading: "How do I update the price of my plot of land?",
      bodycontent:
        "Once you've connected to the site with your wallet (preferably GUARDIIAN Wallet™), you should see your properties listed on your Profile page, where you can update or transfer your property.",
    },
    {
      LHSimage: "/Images/Home/img-why2.svg",
      heading: "How do I send or sell my deed to someone else?",
      bodycontent:
        "In order to transfer or sell your Mars deed, you can use this site, the official OpenSea collection, or interact with the contract directly. Note: These properties are semi-fungible. M.A.R.S.™ is implemented using the original EIP proposal (https://eips.ethereum.org/EIPS/eip-1155) for semi-fungible tokens. The ERC-1155 Multi Token Standard allows for each token ID to represent a new configurable token type, which may have its own metadata, supply and other attributes.",
    },
    {
      LHSimage: "/Images/Home/img-why2.svg",
      heading: "Is this for real?",
      bodycontent:
        "Yes! M.A.R.S. is a real functioning token and exchange. For all intents and purposes, you should treat your Mars plot as a virtual trading card rather than a legally enforceable deed.  It's just less complicated that way.",
    },
    {
      LHSimage: "/Images/Home/img-why2.svg",
      heading: "Is this legal?",
      bgimg: "/Images/Properties-FAQ/card-lg-bg.png",
      bodycontent: [
        <p key={1}>
          The Outer Space Treaty of 1967 clearly states, "outer space, including
          the Moon and other celestial bodies, is not subject to national
          appropriation by claim of sovereignty, by means of use or occupation,
          or by any other means." In other words, no government can claim
          ownership of Mars. It says nothing, however, about individuals
          claiming ownership of Mars.<br></br> Accordingly, IInterstellar™
          provides a decentralized service to manage everyone's claims, virtual
          or otherwise. If in any event there is conflict of law in whatever
          Earth juristiction, purchasers and users of this platform service
          acknowledge that juristictional law will prevail.
        </p>,
      ],
    },
  ];

  return (
    <>
      <Container fluid className="FAQ-contain">
        {/* <img
          src="\Images\Home\galaxy-bg-starts.svg"
          alt="img"
          className="img-fluid bg-home-starts"
        /> */}
        <Navbar About={true} />
        {/* section-1 */}
        {/* <Row>
          <Col className="FAQ-sec1">
            <img
              src="/Images/Properties-FAQ/sec1.svg"
              alt="FAQ-Properties"
              width={"100%"}
            />
            <div className="yourpropertybottom">
              <ReactPlayer
                className="aboutvideoplayers"
                url={videoUrl1}
                controls={true}
              />
            </div>
            <div>
              <p className="description-head-FAQ">Why Properties?</p>
              <p className="description-subhead-FAQ">
                Because real estate is always a good investment, especially when
                it comes to Mars, and because there are also many other richly
                rewarding benefits right here on earth
              </p>
            </div>
            <div className="btn-container">
              <Button type="button" className="btn-BeThere-FAQ" onClick={() => navigate("/yourproperty")}>
                Be There Now
              </Button>
            </div>

            <div className="ethereum-contain-FAQ">
              <img
                src="/Images/About/etherum.svg"
                alt="Ethereum"
                className="ethereum-img-FAQ"
              />
              <p className="ethereum-txt-FAQq">Built with Ethereum</p>
            </div>
            <div className="polygon-context">
              <p className="poly-txt" onClick={ () => {window.location.href="https://guardiianwallet.io/"} }>Install GUARDIIAN Wallet Here</p>
            </div>
          </Col>
        </Row> */}
        <Row>
      <Col className="FAQ-sec1">
        <img
          src="/Images/Properties-FAQ/sec1.svg"
          alt="FAQ-Properties"
          width={"100%"}
        />
        <div className="yourpropertybottom1">
          <ReactPlayer
            className="aboutvideoplayers"
            url={videoUrl1}
            controls={true}
          />
        </div>
        <div>
          <p className="description-head-FAQ">Why Properties?</p>
          <p className="description-subhead-FAQ">
            Because real estate is always a good investment, especially when
            it comes to Mars, and because there are also many other richly
            rewarding benefits right here on earth
          </p>
        </div>
        <div className="btn-container">
          <Button type="button" className="btn-BeThere-FAQ" onClick={() => navigate("/yourproperty")}>
            Be There Now
          </Button>
        </div>

        {/* {!isMobile && (
          <> */}
            
            <div className="polygon-context">
              <div className="ethereum-contain-FAQ">
              <img
                src="/Images/About/etherum.svg"
                alt="Ethereum"
                className="ethereum-img-FAQ"
              />
              <p className="ethereum-txt-FAQq">Built with Ethereum</p>
            </div>
            <div className="poly-bg">
              <p className="poly-txt" onClick={ () => {window.location.href="https://guardiianwallet.io/"} }>Install GUARDIIAN Wallet Here</p>
            </div>
            </div>
          {/* </>
        )} */}
      </Col>
    </Row>
        {/* Accordion Section */}
        <Row>
          <Col className="FAQ-data-contain">
            {FAQCardData.map((item, index) => (
              <FAQCard
                key={index}
                heading={item.heading}
                LHSimage={item.LHSimage}
                bodycontent={item.bodycontent}
                bgimg={item.bgimg}
              />
            ))}
          </Col>
        </Row>
        {/* About Section */}
        <Row>
          <Col className="about-FAQ-contain">
            <img
              src="/Images/Home/about-mars.svg"
              alt="About"
              className="img-fluid set-custom-width"
            />
          </Col>
        </Row>
        {/* Footer */}
        <Row>
          <Col className="FAQ-footer">
            <Footer />
          </Col>
        </Row>
      </Container>
    </>
  );
}
