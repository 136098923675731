//
import { Row, Col, Container } from "react-bootstrap";
import "./Newlandingpg.css";
import Button from "react-bootstrap/Button";
import Navbar from "../../Components/Custom/Navbar/Navbar";
import Footer from "../../Components/Custom/Footer/Footer";
import { useGLTF, OrbitControls, Stage } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import MarsTexture from "../../Components/Mars/MarsTextures";
import { useState, useEffect } from "react";
// import MarsGlobe from "../../Components/Mars/MarsGlobe";

import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import HomeScreenLoader from "../../Components/loader/HomeScreenLoader";

export default function NewLandingPage() {
  const videoUrl2 = "/Images/Landingpg/Spinning-Mars.mp4";
  const videoUrl1 = "https://www.youtube.com/watch?v=u59lISCVe2A";
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 10000); // Hide loader after 30 seconds
  }, []);

  return (
    <>
      {showLoader && <HomeScreenLoader />}
      <div className="landing-pg">
        <Row className="custom-row">
          <Col md={6}>
            <div className="star-img">
              <img src="/Images/Landingpg/Logo-landing.svg" alt="Logo" />
            </div>
            <div className="landing-lhs">
              <ReactPlayer
                className="landingvideo"
                url={videoUrl1}
                controls={true}
                config={{
                  playicon: { width: "12px" },
                }}
              />
            </div>
            <div className="heading-newlanding">
              {/* <MarsGlobe /> */}
              <img
                src="/Images/Landingpg/heading.svg"
                alt="Heading"
                className="heading-img"
              />
            </div>
            <div className="btn-container" onClick={() => navigate("/Home")}>
              <Button
                type="button"
                className="landing-btn-Martian"
                onClick={() => navigate("/Home")}
              >
                Enter M.A.R.S.
              </Button>
            </div>
            <div className="guardian-contain">
              <p
                className="guard-txt"
                onClick={() => {
                  window.location.href = "https://guardiianwallet.io/";
                }}
              >
                Install GUARDIIAN Wallet Here
              </p>
            </div>
            {/* <div className="d-flex align-items-center justify-content-center">
              <p className="web-txt">This website requires web3.js</p>
            </div> */}
          </Col>
          <Col md={6}>
            <div className="Mars-img">
              <img
                src="/Images/Landingpg/Mars.svg"
                alt="Mars"
                width={"100%"}
                height={"100%"}
              />
            </div>
            <div className="landing-RHS">
              {/* <img
                src="/Images/Landingpg/homebg.svg"
                alt="landingimage"
                className="mars-landing-img"

              /> */}
              <MarsTexture />
            </div>
          </Col>
          <div style={{ marginTop: "-40px",  flexDirection:"column-reverse", gap:"20px",alignItems:"flex-end", justifyContent:"center" }} className="ethreum-landing">
            <div style={{ }}>
              <p
                className="Built-with-Ethereum"
                style={{ textAlign: "Center", fontSize: "20px" }}
              >
                This website requires web3.js
              </p>
            </div>
            <div className="d-flex">
              <p className="Built-with-Ethereum pe-2">Built with Ethereum</p>
              <img
                src="/Images/About/etherum.svg"
                alt="Ethereum"
                className="ethereum-img-landing"
              />
            </div>
          </div>
        </Row>
      </div>
      <div className="landing-pg-mb">
        <Navbar />
        <Row className="custom-row">
          <Col>
            <div className="heading-newlanding">
              <img
                src="/Images/Landingpg/heading-mb.svg"
                alt="heading"
                className="landing-head-img"
              />
            </div>
            <div className="landing-RHS">
              {/* <ReactPlayer
                className="landingvideoRHS"
                url={videoUrl2}
                controls={false}
                config={{
                  file: {
                    attributes: {
                      autoPlay: true,
                      muted: true,
                      loop: true,
                    },
                  },
                }}
              /> */}
              {/* <img
                src="/Images/Landingpg/mars-responsive.svg"
                alt="landingimage"
              /> */}
              <MarsTexture />
            </div>
            <div className="btn-container">
              <Button
                type="button"
                className="landing-btn-Martian"
                onClick={() => navigate("/Home")}
              >
                Enter M.A.R.S.
              </Button>
            </div>
            <div className="guardian-contain">
              <p
                className="guard-txt"
                onClick={() => {
                  window.location.href = "https://guardiianwallet.io/";
                }}
              >
                Install GUARDIIAN Wallet Here
              </p>
            </div>
            <div className="landing-lhs">
              <ReactPlayer
                className="landingvideo"
                url={videoUrl1}
                controls={true}
              />
            </div>
            <div>
              <p
                style={{
                  width: "100%",
                  color: "rgba(222, 78, 68, 1)",
                  textAlign: "center",
                  paddingTop: "20%",
                }}
                className="web-txt"
              >
                This website requires web3.js
              </p>
            </div>
            <div className="ethereum-landing">
              <div className="ethereum-contain-landing">
                <img
                  src="/Images/About/etherum.svg"
                  alt="Ethereum"
                  className="ethereum-img-landing"
                />
                <p className="ethereum-txt-landing">Built with Ethereum</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
          <Col className="LandingPage-footer">
            <Footer />
          </Col>
        </Row>
    </>
  );
}
