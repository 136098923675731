import contractAbi from "../../utils/Contracts/ctizenship.json";
import { CitizenShip,ApprovedToken } from "../../utils/Contracts/ContractAddress";
import approveAbi from "../../utils/Contracts/approve.json";
import { useCallback } from "react";


export const useCitizenship = ({ account }) => {
  const citizenHook = useCallback(
    async (account, customweb3, plotId) => {
      console.log("inside the contract");
      const contract = new customweb3.eth.Contract(contractAbi, CitizenShip);
      const approvecontract = new customweb3.eth.Contract(
        approveAbi,
        ApprovedToken
      );
      try {
        const fee1=49 
        const feeinString=fee1.toString()
        const valuesend = customweb3.utils.toWei(feeinString, "ether");

        console.log(account,customweb3,plotId)

       
          const approveestimatedGas = await approvecontract.methods
          .approve(CitizenShip, valuesend)
          .estimateGas({
            from: account,
          });
          const approve = await approvecontract.methods.approve(CitizenShip, valuesend).send({
            from: account,
            gas: approveestimatedGas,
          });
          const estimatedGas = await contract.methods
          .buyCitizenship(plotId)
          .estimateGas({
            from: account,
          });
        const details = await contract.methods.buyCitizenship(plotId).send({
          from: account,
          gas: estimatedGas,
        });

        console.log("Transaction details:", details);
        return details;
      } catch (error) {
        throw error;
      }
    },
    [account]
  );
  return { citizenHook: citizenHook };
};
export default useCitizenship;
