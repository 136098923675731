import React from "react";
import gifFile from "../../Images/astronaut-dancing.gif"; 

function ScreenLoader({ text }) {
  return (
    <div
      className="position-fixed d-flex justify-content-center align-items-center"
      style={{
        zIndex: 999999999999,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.5)", 
      }}
    >
      <div className="d-flex flex-column align-items-center">
        <img
          src={gifFile} 
          alt="Loading..."
          style={{ width: 200, height: 200 }} 
        />
        <h2 className="text-white mt-4">{text}</h2>
      </div>
    </div>
  );
}

export default ScreenLoader;
