import { Row, Col, Container } from "react-bootstrap";
import "./Home.css";
import Button from "react-bootstrap/Button";
import Navbar from "../../Components/Custom/Navbar/Navbar";
import Footer from "../../Components/Custom/Footer/Footer";
import Accordion from "../../Components/Cards/Accordion/Accordion";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/Web3Connection/useAuth";
import Web3Modal from "../../Components/modal/Web3Modal";
import { useState } from "react";
import ReactPlayer from "react-player";
import { useMediaQuery } from "react-responsive";

export default function Home() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [open, SetOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 375 });
  const videoUrl = "https://youtu.be/tzrECrSvFZY";

  const accordionsData = [
    {
      imglogo: "/Images/Home/Why-Mars.svg",
      headtxt: "Why Mars?",
      bodytxt: [
        <p>
          Firstly, Venus and Mercury are seriously hot! And not in a good way.
          You simply can't habitat there without getting a 3rd degree suntan
          burn, and there’s nothing there worth anything anyway, so why bother
          buying property there? Secondly, Mars is close by, not like a weekend
          drive, but close enough. It's also habitable and lots of people want
          to go there, so no doubt property values will increase as NFTs are
          bought and sold, and that's good enough reason to get some.
        </p>,
      ],
    },
    {
      imglogo: "/Images/Home/img-why2.svg",
      headtxt: "Why NFTs?",
      bodytxt:
        "Simple, because NFTs are easy to buy, hold, and sell, usually for a profit, and sometimes for a serious profit.  So why not get yourself some?  Plus, real estate, especially super cool real estate always increases in value, and Mars is definitely super cool.",
    },
    {
      imglogo: "/Images/Home/img-why1.svg",
      headtxt: "Why INTERSTELLAR?",
      bodytxt: [
        <p key={1}>
          Simple, because IINTERSTELLAR™ is seriously connected and gives you
          serious connections. And besides, where else have you heard or seen
          Mars property for sale? How about Citizenships? So there you go! Also,
          IINTERSTELLAR™ utilizes R.E.A.L. technology, so you can actually get
          paid to purchase Martiian property. It just doesn’t get any better
          than that!
        </p>,
      ],
    },
    {
      imglogo: "/Images/Home/img-why2.svg",
      headtxt: "What's the Process?",
      bodytxt:
        "Only $29 for Properties, and $49 for Citiizenships!  (Citiizenships receive additional $28 in M.A.R.S. tokens). Purchasing Properties or Citiizenships is a simple, straight-forward process that ensures your complete satisfaction. If you do happen to have any questions we offer a FAQ sections for both Properties and Citiizenships. It couldn't be any easier.",
    },
  ];

  const trustWallet = async () => {
    try {
      // props.setModalShows(false);
      await login("walletconnect", 1);
      localStorage.setItem("connectorId", "walletconnect");
      localStorage.setItem("flag", "true");
      // setLog(true);
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <>
      <Container fluid className="home-contain">
        {/* <img
          src="/Images/Home/galaxy-bg-starts.svg"
          alt="img"
          className="img-fluid bg-home-starts"
        /> */}
        <Navbar />

        {/* Interstellar Section */}
        <Row>
          <Col className="interstellar-contain">
            <img
              src="/Images/Home/homenewwhole.svg"
              alt="Interstellar"
              width={"100%"}
            />
            <div className="interstellar-context">
              {/* <div>
                <p className="description">
                  Secure your spot on the Red Planet today<br></br> with a
                  hectare of prime Martiian land and<br></br> Martiian
                  Citiizenship
                </p>
              </div> */}
              {/* {isMobile && (
                <div className="button-group">
                <Button
                  type="button"
                  className="btn-Martian2"
                  onClick={() => navigate("/yourproperty")}
                >
                  Be Martiian Properties
                </Button>
                <Button
                  type="button"
                  className="btn-Martian2"
                  onClick={() => navigate("/certificate")}
                >
                  Be Martiian Citiizenship
                </Button>
              </div>
              )} */}
              
            </div>
            <div className="interstellar-context">
              <div>
                <p className="description">
                  Secure your spot on the Red Planet today<br></br> with a
                  hectare of prime Martiian land and<br></br> Martiian
                  Citiizenship
                </p>
              </div>
              {/* {!isMobile && ( */}
                <div className="button-group">
                  <button
                    // type="button"
                    className="Home__btn-Martian1"
                    onClick={() => navigate("/yourproperty")}
                  >
                    Be Martiian Properties
                  </button>
                  <button
                    // type="button"
                    className="Home__btn-Martian1"
                    onClick={() => navigate("/certificate")}
                  >
                    Be Martiian Citiizenship
                  </button>
                </div>
              {/* )} */}
            </div>

            <div className="polygon-context">
              <p
                className="poly-txt"
                onClick={() => {
                  window.location.href = "https://guardiianwallet.io/";
                }}
              >
                Install GUARDIIAN Wallet Here
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
          <div className="home__vedio">
          <ReactPlayer
            className="Home__videoPlayers"
            url={videoUrl}
            controls={true}
          />
        </div></Col>
        </Row>

        {/* Accordions Section */}
        <Row>
          <Col className="accordion-contain">
            {accordionsData.map((accordion, index) => (
              <Accordion
                key={index}
                imglogo={accordion.imglogo}
                headtxt={accordion.headtxt}
                bodytxt={accordion.bodytxt}
              />
            ))}
          </Col>
        </Row>

        {/* <button>metaMask</button>

        <button>Trust Wallet</button> */}

        {/* About Section */}
        <Row>
          <Col className="about-contain">
            <img
              src="/Images/Home/about-mars.svg"
              alt="About"
              className="img-fluid set-custom-width"
            />
          </Col>
        </Row>

        <Row>
          <Col className="Home-footer">
            <Footer />
          </Col>
        </Row>
      </Container>
      <Web3Modal show={open} setModalShows={SetOpen} />
    </>
  );
}
