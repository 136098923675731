import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  plot: null,
};

export const plotslice = createSlice({
  name: 'plotslice',
  initialState,
  reducers: {
    plotId: (state, action) => {
      state.plot = action.payload; // Use action.payload to get the value passed with the action
      
    },
  },
});

// Action creators are generated for each case reducer function
export const { plotId } = plotslice.actions;

export default plotslice.reducer;
