import React from "react";
import "./TilesCard.css";
import { Row, Col, Container } from "react-bootstrap";

export default function TilesCard({headtext,subheadtext,bodytext,bgimg}) {
  const defaultImg = "/Images/Home/homeinfo-card-bg.svg";
  const backgroundImageStyle = {
      backgroundImage: `url(${bgimg || defaultImg})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top center",
      backgroundSize:"cover"
  };
  return (
    <>
      <Row>
        <Col className={headtext? "tiles-card-contain" : "tiles-card-nohead"} style={backgroundImageStyle}>
        <p className={headtext ? "card-head" : "card-nohead"}>{headtext}</p>

          <p className="card-subhead">{subheadtext}</p>
          <p className="card-bodytxt">
            {bodytext}
          </p>
        </Col>
      </Row>
    </>
  );
}
