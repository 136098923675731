import { useWeb3React } from "@web3-react/core";
import { useCallback } from "react";
import { getLibraryForSign } from "../../utils/Web3Connection/web3React/web3React";
import "react-toastify/dist/ReactToastify.css";
import useWeb3 from "./useWeb3";
import { connectorsByName } from "../../utils/Web3Connection/web3React/web3React";
import { ethers } from "ethers";
import { ErrorToast } from "../../Components/toast/Toast";

export const Signature = (data) => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  var library = null;
  const connectorId =
    typeof window !== "undefined"
      ? window.localStorage.getItem("connectorId")
      : null;
  if (connectorId === "injected" && account) {
    library = getLibraryForSign(web3?.givenProvider);
  } else {
    if (connectorsByName.walletconnect.provider) {
      library = getLibraryForSign(connectorsByName.walletconnect.provider);
    }
  }
  const sign = useCallback(
    async (accountData) => {
      if (library && account) {
        try {
          const connectorId =
            typeof window !== "undefined"
              ? window.localStorage.getItem("connectorId")
              : null;
          if (connectorId === "injected") {
            library = getLibraryForSign(web3?.givenProvider);
          } else {
            library = getLibraryForSign(
              connectorsByName.walletconnect.provider
            );
          }
          let signature = await library.send("personal_sign", [
            ethers.utils.hexlify(
              ethers.utils.toUtf8Bytes(account?.toLowerCase())
            ),
            account.toLowerCase(),
          ]);

          return signature;
        } catch (error) {
          ErrorToast(error)
          return false;
        }
      }
    },
    [account, library, data]
  );
  return { userSign: sign };
};
export default Signature;
