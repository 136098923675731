import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import marsTexture from "../../Images/Your-property/marstexture.webp";
import { useDispatch } from "react-redux";
import { plotId } from "../../services/reducer/plotslice";
import { useMediaQuery } from "react-responsive";

const MarsTextures = () => {
  const sceneRef = useRef();
  const rendererRef = useRef();
  const controlsRef = useRef();
  const sphereRef = useRef();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  useEffect(() => {
    const w = isMobile ? 250 : 1000;
    const h = isMobile ? 250 : 600;

    const scene = new THREE.Scene();
    const fov = 70;
    const aspect = w / h;
    const near = 0.1;
    const far = 100;
    const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
    camera.position.x = 75;

    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(w, h);
    rendererRef.current = renderer;
    while (sceneRef.current.firstChild) {
      sceneRef.current.removeChild(sceneRef.current.firstChild);
    }
    sceneRef.current.appendChild(renderer.domElement);

    const loader = new THREE.TextureLoader();
    const texture = loader.load("/Images/Mars_colour_Map_8k 1 (4).png");
    // texture.minFilter = THREE.LinearFilter; // Use linear filtering for better texture quality
    texture.wrapS = THREE.RepeatWrapping; // Repeat texture in the horizontal direction if needed
    texture.wrapT = THREE.RepeatWrapping;

    const desiredTriangles = 3000;
    const totalSegments = Math.ceil(Math.sqrt(desiredTriangles / 25) - 1);
    const geometry = new THREE.IcosahedronGeometry(
      40,
      totalSegments,
      totalSegments
    );
    const material = new THREE.MeshBasicMaterial({
    //   vertexColors: true,    
      map: texture,
      // color: new THREE.Color(0xfec7ab), // Set color to desired solid color

    });

    const sphere = new THREE.Mesh(geometry, material);
    scene.add(sphere);
    sphereRef.current = sphere;

    const colors = new Float32Array(geometry.attributes.position.count * 3);
    geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));
    resetColors(); 
    scene.add(sphere);
    // scene.add(wireframe);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableZoom = false;
    controls.enableRotate = false;
    controls.autoRotate = true; 
    controls.autoRotateSpeed = 0.2; 
    controlsRef.current = controls;

    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    let hoverFace = null;
    let lastIntersected = null;

    function setColor(face, color) {
      const ca = geometry.attributes.color;
      [face.a, face.b, face.c].forEach((vertexIndex) => {
        ca.setXYZ(vertexIndex, color.r, color.g, color.b);
      });
      ca.needsUpdate = true;
    }

    function resetColors() {
      const ca = geometry.attributes.color;
      for (let i = 0; i < geometry.attributes.position.count; i++) {
        ca.setXYZ(i, 0.5, 0.5, 0.5); // Reset to gray color
      }
      ca.needsUpdate = true;
    }
    const canvas = renderer.domElement;

    // window.addEventListener("mousemove", onMouseUp, false);
    // canvas.addEventListener("mousemove", onMouseMove, false);
    // canvas.addEventListener("click", onMouseClick, false);

  



    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      rendererRef.current.render(scene, camera);
    };
    animate();

    return () => {
    //   window.removeEventListener("onmousemove", onMouseUp);
    };
  }, [isMobile]);

  return (
    <div ref={sceneRef} style={{ cursor: isMobile ? "pointer" : "auto"}} />
  );
};

export default MarsTextures;
